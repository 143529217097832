import { ref } from 'vue'
import axios from 'axios'
import { serverResponseStatusesAuthorization } from '../Pages/the-signup/enums/serverResponseStatusesAuthorization'
import { AuthorizationStatusData } from '@/Pages/the-signup/interfaces/AuthorizationStatusData'
import {
    LoginForgotRequest,
    LoginForgotResetLinkRequest,
    LoginRequest,
    SignupEndpointRequest,
    SignUpVerificationRequest
} from '@/Pages/the-signup/interfaces/AuthorizationRequests'
import { User } from '@/types'

export const isLoadingRequestAuthorization = ref(false)

export const isUserLogged = ref(false)

export const userInfo = ref<User>({
    name: '',
    avatar: null,
    canEdit: false,
    wishlist: []
})

export const setUserInfo = (userData: User): void => {
    userInfo.value = userData
}

export const toggleIsUserLogged = (isLogged: boolean): void => {
    isUserLogged.value = isLogged
}

export const emailExist = ref(false)

export const setEmailExist = (value: boolean): void => {
    emailExist.value = value
}

export const emailAuthorizationError = ref('')

export const setEmailAuthorizationError = (value: string): void => {
    emailAuthorizationError.value = value
}

export const passwordAuthorizationError = ref('')

export const setPasswordAuthorizationError = (value: string): void => {
    passwordAuthorizationError.value = value
}

export const tooSoonSinceLastEmail = ref(false)

export const setTooSoonSinceLastEmail = (value: boolean): void => {
    tooSoonSinceLastEmail.value = value
}

export const isSubmitted = ref(false)

export const setIsSubmitted = (value: boolean): void => {
    isSubmitted.value = value
}

export const errorStatusFromServer = ref('')

export const setErrorStatusFromServer = (value: string): void => {
    errorStatusFromServer.value = value
}

export const setAuthorizationStatus = (data: AuthorizationStatusData): void => {

    if (data.status === serverResponseStatusesAuthorization.EMAIL_ALREADY_EXISTS || data.status === serverResponseStatusesAuthorization.ALREADY_LOGGED) {
        setEmailExist(true)

        setTooSoonSinceLastEmail(false)
        setIsSubmitted(false)
        setEmailAuthorizationError('')
        setErrorStatusFromServer('')

    } else if (data.status === serverResponseStatusesAuthorization.VALIDATION_ERROR || data.status === serverResponseStatusesAuthorization.AUTH_ERROR) {
        setEmailAuthorizationError('The email must be a valid email address.')

        if (data.error) {
            setErrorStatusFromServer(data.error)
        }

        setEmailExist(false)
        setTooSoonSinceLastEmail(false)
        setIsSubmitted(false)

    } else if (data.status === serverResponseStatusesAuthorization.EMAIL_SENT || data.status === serverResponseStatusesAuthorization.SUCCESS) {
        setIsSubmitted(true)

        setEmailExist(false)
        setTooSoonSinceLastEmail(false)
        setEmailAuthorizationError('')
        setErrorStatusFromServer('')

    } else if (data.status === serverResponseStatusesAuthorization.TOO_SOON_SINCE_LAST_EMAIL) {
        setTooSoonSinceLastEmail(true)

        setEmailExist(false)
        setIsSubmitted(false)
        setEmailAuthorizationError('')
        setErrorStatusFromServer('')
    }
}

export const clearAuthorizationFormData = (): void => {
    setEmailExist(false)
    setIsSubmitted(false)
    setTooSoonSinceLastEmail(false)
    setEmailAuthorizationError('')
    setPasswordAuthorizationError('')
    setErrorStatusFromServer('')
    isLoadingRequestAuthorization.value = false
}

//signUp
export const signUpEndpoint = (request: SignupEndpointRequest, ...callbacks: Array<(data: AuthorizationStatusData) => void>): void => {

    isLoadingRequestAuthorization.value = true

    axios
        .post(`${ request.url }`, { email: request.email })
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestAuthorization.value = false
        })
        .catch((error) => {
            console.error('Error at sign up endpoint:', error)

            isLoadingRequestAuthorization.value = false
        })
}


//signUpEmailVerification
export const signUpVerificationEndpoint = (request: SignUpVerificationRequest, ...callbacks: Array<(data: AuthorizationStatusData) => void>): void => {

    isLoadingRequestAuthorization.value = true

    const postData = {
        email: request.email,
        password: request.password
    }

    axios
        .post(request.token, postData)
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))
            isLoadingRequestAuthorization.value = false
        })
        .catch((error) => {
            console.error('Error at sign up verification endpoint:', error)

            isLoadingRequestAuthorization.value = false
        })
}

//login
export const loginEndpoint = (request: LoginRequest, ...callbacks: Array<(data: AuthorizationStatusData) => void>): void => {

    isLoadingRequestAuthorization.value = true

    const postData = {
        username: request.username,
        password: request.password,
        remember: request.remember
    }

    axios
        .post(request.url, postData)
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))
            isLoadingRequestAuthorization.value = false
        })
        .catch((error) => {
            console.error('Error at login endpoint:', error)

            isLoadingRequestAuthorization.value = false
        })
}

//login forgot
export const loginForgotEndpoint = (request: LoginForgotRequest, ...callbacks: Array<(data: AuthorizationStatusData) => void>): void => {

    isLoadingRequestAuthorization.value = true

    const postData = {
        email: request.email
    }

    axios
        .post(request.url, postData)
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))
            isLoadingRequestAuthorization.value = false
        })
        .catch((error) => {
            console.error('Error at login forgot endpoint:', error)

            isLoadingRequestAuthorization.value = false
        })
}

// forgot login reset link
export const forgotLoginResetLinkEndpoint = (request: LoginForgotResetLinkRequest, ...callbacks: Array<(data: AuthorizationStatusData) => void>): void => {

    isLoadingRequestAuthorization.value = true

    const postData = {
        token: request.token,
        password: request.password,
        user: request.userID
    }

    axios
        .post(request.token, postData, {
            params: {
                u: request.userID
            }
        })
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))
            isLoadingRequestAuthorization.value = false
        })
        .catch((error) => {
            console.error('Error at forgot login reset link endpoint:', error)

            isLoadingRequestAuthorization.value = false
        })
}