export enum serverResponseStatusesAuthorization {
    EMAIL_ALREADY_EXISTS = 'emailAlreadyExists',
    VALIDATION_ERROR = 'validationError',
    EMAIL_SENT = 'emailSent',
    ALREADY_LOGGED = 'alreadyLogged',
    TOO_SOON_SINCE_LAST_EMAIL = 'tooSoonSinceLastEmail',
    SUCCESS='success',
    INVALID_VERIFY_LINK='invalidVerifyLink',
    NEW_USER_LOGGED='newUserLogged',
    AUTH_ERROR='authError',
    USER_NOT_FOUND='userNotFound',
    INVALID_LINK='invalidLink',
}